@import 'override-bootstrap';

$bp-mobile-menu: (
  up: 'lg',
  down: 'lg'
);

$header-desktop-height: 5.75rem;
$header-mobile-height: 73px;

//[data-bs-theme="dark"] {
//  --bs-body-bg: $black;
//  --bs-body-color: $white;
//}

$body-bg-dark: #000;
$body-color-dark: #fff;
