@import '~bootstrap/scss/functions';
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.header.header-1 ~ .main-content {
  .main-hero {
    &:first-child {
      @include media-breakpoint-down(lg) {
        padding-top: 74px;
      }
    }
  }
}

.main-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (orientation: landscape) {
    min-height: calc(100vh - 11.375rem);
  }

  @media (orientation: landscape) and (max-width: #{map-get($grid-breakpoints, 'xxl')}) {
    min-height: calc(100vh - 9.375rem);
  }

  @media (orientation: portrait) {
    min-height: 750px;
  }

  @include media-breakpoint-down(lg) {
    min-height: 0;
  }

  @include media-breakpoint-up(xxxl) {
    min-height: 56.875rem;
  }

  &:first-child {
    padding-top: $header-desktop-height;
    padding-bottom: 1.25rem;

    @include media-breakpoint-down(xxl) {
      padding-bottom: 0;
    }

    @include media-breakpoint-down(lg) {
      padding-top: $header-mobile-height;
    }
  }

  &__top {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 5rem;

    @include media-breakpoint-down(xxl) {
      padding-top: 1rem;
      padding-bottom: 3rem;
    }

    @include media-breakpoint-down(md) {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  &__spinner {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -30%);
    border: none;
    background: transparent;

    @include media-breakpoint-down(lg) {
      transform: translate(-50%, -50%);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover {
      .hero-spinner__group {
        animation-play-state: paused;
      }
    }

    svg {
      width: 8.75rem;
      height: 8.75rem;

      @include media-breakpoint-down(lg) {
        width: 6rem;
        height: 6rem;
      }
    }
  }

  .section-subtitle {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__content {
    max-width: 45rem;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }

    &--bottom {
      margin-top: 3.5rem;
      padding-right: 13.25rem;

      @include media-breakpoint-down(xxl) {
        margin-top: 1.875rem;
      }

      @include media-breakpoint-down(lg) {
        margin-top: 24px;
        padding-right: 6.25rem;
      }

      @include media-breakpoint-down(md) {
        padding-right: 0;
      }

      .content-wrapper {
        @include media-breakpoint-up(lg) {
          max-width: 30.8rem;
        }

        @include media-breakpoint-down(md) {
          max-width: 300px;
        }

        br {
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
    }
  }

  &__video {
    @include media-breakpoint-down(xxl) {
      max-width: 26.25rem;
      margin: 0 auto;
    }

    .of-cover__wrapper {
      padding-bottom: 100%;
      overflow: visible;

      .of-cover {
        border-radius: 2.5rem;
        border: 1px solid $white;

        @include media-breakpoint-down(md) {
          border-radius: 1.5rem;
        }

        @include theme-mode(dark) {
          border: none;
        }
      }
    }

    img {
      max-width: calc(100% + 4px);
      width: calc(100% + 4px);
      margin: 0 -2px;
    }

    @include media-breakpoint-down(md) {
      max-width: 85%;
      margin: 0 auto;
    }
  }

  &__bottom {
    &--left {
      flex: 1 1 100%;
      width: 100%;
      padding-right: $grid-gutter-width;

      .content-wrapper {
        @include media-breakpoint-up(lg) {
          max-width: 30.8rem;
        }

        br {
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
    }

    &--right {
      width: 26.25rem;
      flex: 0 0 26.25rem;

      h5 {
        line-height: 1.5rem;
      }

      a {
        color: $gray-600;
        text-decoration: none;

        &:hover {
          color: $body-color;
        }
      }
    }
  }
}
